import React from 'react';
import presenter from '../images/presenter.jpeg';
import SectionTitle from './SectionTitle';
import RoundPicture from './RoundPicture';
import './Presents.css';

const Presents = ({ presentsRef }) => (
    <div ref={presentsRef} className="presents">
        <RoundPicture image={presenter} />
        <SectionTitle text={'Presenter'} />
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Vi har fått en del förfrågningar kring presenter. Vi vill
                återigen betona att detta inte är av någon större vikt för oss.
                Men för den som önskar så har vi sammanställt några tips här
                nedan:
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>PotterJo - Daisy</strong>
                <br></br>
                Detta är en porslinsserie som ligger oss varmt om hjärtat, och
                det är svårt för oss att se hur man någonsin kan ha för många-
                eller mycket av något därifrån. Vi är lite extra svaga för
                följande färger: Grey, Bordeaux, Forest och Sienna.<br></br>
                <a href="https://potteryjo.com/collections/daisy">
                    Hemsida finns här
                </a>
                (Finns även på Cervera och Åhléns)
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>Riedel Vinglas</strong>
                <br></br>
                Något vi märkt med tiden är att vi är fruktansvärt dåliga på att
                inhandla egna vinglas. Vi har suktat efter dessa vinglas men har
                hittills inte hunnit slå till. <br></br>
                <a href="https://royaldesign.se/veritas-vinglas-old-world-pinot-noir-2-pack">
                    Rödvin
                </a>
                <br></br>
                <a href="https://royaldesign.se/veritas-vinglas-sauvignon-blanc-2-pack">
                    Vitvin
                </a>
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>Ikea</strong>
                <br></br>
                Ett presentkort måhända kännas tråkigt, men ack så uppskattat!
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>Trädgårdsverktyg</strong>
                <br></br>
                Vi har ju gått och blivit tomtägare, och med det kommer även en
                hel rad måsten i vår trädgård. Till vår hjälp har vi en del
                verktyg som den tidigare ägaren skänkte oss, men de har sett
                bättre dagar.
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>Annat</strong>
                <br></br>
                Bubbel och annat gott är såklart alltid välkommet!
            </p>
        </div>
    </div>
);

export default Presents;
