import React, { useState } from 'react';
import paoss from '../images/bildpaoss.jpg';
import paoss2 from '../images/bildpaoss2.png';
import RsvpForm from './RsvpForm';
import info from '../images/info-icon.svg';
import corona from '../images/corona.png';
import { ReactComponent as Icon } from '../images/info-icon.svg';

import './Hero.css';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

// background: rgba(128,91,84,.5);
const Hero = ({ heroRef, moreInfoRef }) => (
    <div ref={heroRef} className="hero">
        <img className="hero_image_background" src={paoss} />
        <img className="hero_image_background2" src={paoss2} />
        <div className="hero_image_background_overlay">
            <div className="hero_text_old">11 ● 7 ● 2020</div>
            <div className="hero_text">10 ● 7 ● 2021</div>
            <div className="hero_information_container">
                <div className="hero_information_icon_container">
                    <div className="hero_information_icon_circle" />
                    <Icon className="hero_information_icon" />
                </div>
                <div className="hero_information_title">
                    Ny information<br></br>10 ● 7 ● 2021
                </div>
                <button
                    className="hero_information_text"
                    onClick={() => scrollToRef(moreInfoRef)}
                >
                    Läs mer här!
                </button>
            </div>
            {/* <svg viewBox='0 0 105 105'>
        <path d='M 25,60 
           a 20,20 1 0,0 0,40 
           h 50 
           a 20,20 1 0,0 0,-40 
           a 10,10 1 0,0 -15,-10 
           a 15,15 1 0,0 -35,10  
           z' />
      </svg> */}
        </div>
    </div>
);

export default Hero;
//  // https://hooks.slack.com/services/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
// const Hero = ({ heroRef }) => {
//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       console.log(formState);
//
//       const res = await axios({
//       url: 'https://script.google.com/macros/s/AKfycbzpSorSMdwt322A-8OwEP0h22hO-rN3Sd2d3e2o0ZbQ1msNRhc/exec',
//       method: "POST",
//       headers: {
//         'Content-Type': 'application/jsonp',
//       },
//       crossdomain: true,
//       // headers: {
//       //   'Content-Type': 'text/plain;charset=utf-8',
//       // },
//       data: JSON.stringify(formState),
//     });
//
//     const url ='https://script.google.com/macros/s/AKfycbzpSorSMdwt322A-8OwEP0h22hO-rN3Sd2d3e2o0ZbQ1msNRhc/exec';
//
//   //   const urlWithPara = `${url}?form_field_1=${formState.form_field_1}?form_field_2=${formState.form_field_2}`;
//   //   console.log('urlWithPara', urlWithPara);
//   //   const encoded = encodeURIComponent(urlWithPara);
//   //   const res = await fetch(encoded, {
//   //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
//   //   mode: 'no-cors', // no-cors, *cors, same-origin
//   //   // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//   //   // credentials: 'same-origin', // include, *same-origin, omit
//   //   headers: {
//   //     'Content-Type': 'application/json'
//   //     // 'Content-Type': 'application/x-www-form-urlencoded',
//   //   },
//   //   // redirect: 'follow', // manual, *follow, error
//   //   // referrer: 'no-referrer', // no-referrer, *client
//   //   // data: JSON.stringify(formState) // body data type must match "Content-Type" header
//   // })
//
//
//   //   const res = await fetch(url, {
//   //   method: 'GET', // *GET, POST, PUT, DELETE, etc.
//   //   mode: 'no-cors', // no-cors, *cors, same-origin
//   //   // cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
//   //   // credentials: 'same-origin', // include, *same-origin, omit
//   //   headers: {
//   //     'Content-Type': 'application/json'
//   //     // 'Content-Type': 'application/x-www-form-urlencoded',
//   //   },
//   //   // redirect: 'follow', // manual, *follow, error
//   //   // referrer: 'no-referrer', // no-referrer, *client
//   //   data: JSON.stringify(formState) // body data type must match "Content-Type" header
//   // });
//
//
//     // const res = await fetch(url, { method: 'POST', mode: 'no-cors', body: JSON.stringify(formState)});
//
//     // let sendingData = new FormData(); // adjusted here
//     // sendingData.append('hej', 'dsadsa');
//     // sendingData.append('form_field_1', 'dsdsaadsa');
//     //
//     // const res = await fetch(url, {
//     //   method: 'POST',
//     //   body: sendingData,
//     //   mode: 'no-cors',
//     //
//     // }); // adjusted he
//     // const res = await fetch(url, {
//     //   method: 'GET',
//     //   mode: 'no-cors',
//     //   headers: {
//     //     'Content-Type': 'application/json'
//     //   },
//     //   data: JSON.stringify(formState),
//     // });
//
//     console.log(res);
//     } catch (e) {
//       console.log(e);
//     };
//   };
//   const [formState, setFormState] = useState({
//     form_field_1: '',
//     form_field_2: '',
//     form_field_3: '',
//     form_field_4: '',
//   });
//   const handleFormChange = (e) => setFormState({
//   ...formState,
//   [e.target.name]: e.target.value,
// });
//   return (
//     <div ref={heroRef} className="hero">
//       {/*<div className="hero_image_background_overlay" /> */}
//       <img className="hero_image_background" src={rose} />
//       {/*<form className="test-form-center" onSubmit={handleSubmit}>
//         <div>
//           <label>Field 1</label>
//           <input type="text" name="form_field_1" placeholder="Field 1" onChange={handleFormChange}/>
//         </div>
//
//         <div>
//           <label>Field 2</label>
//           <input type="text" name="form_field_2" placeholder="Field 2" onChange={handleFormChange}/>
//         </div>
//
//         <div>
//           <label>Field 3</label>
//           <input type="text" name="form_field_3" placeholder="Field 3" onChange={handleFormChange}/>
//         </div>
//
//         <div>
//           <label>Field 4</label>
//           <input type="text" name="form_field_4" placeholder="Field 4" onChange={handleFormChange}/>
//         </div>
//
//         <div>
//           <button type="submit" >Submit</button>
//         </div>
//       </form>*/}
//     </div>
//   );
// }
//
//
//
//     //     <img className="hero_image_background" src={hand} />
//
//
// export default Hero;
