import React from 'react';
import program3 from '../images/program3.png';
import SectionTitle from './SectionTitle';
import TextSection from './TextSection';
import SectionUnderTitle from './SectionUnderTitle';
import RoundPicture from './RoundPicture';
import './Timetable.css';

const text =
    'Lördagen den elfte juli så kommer vi att vigas vid kl.16:00. Önskar man anlända lite tidigare så går det alldeles utmärkt, slottet kommer att stå öppet för den som vill ta det lite lugnt innan ceremonin. Om man kommer att sova kvar så har man även möjlighet att få tillgång till sitt rum fr.o.m. kl.14:00. Middagen kommer att börja vid kl.18:00 och kvällen slutar sedan med fest!';

const Timetable = ({ timetableRef }) => (
    <div ref={timetableRef} className="timetable">
        <RoundPicture image={program3} />
        <SectionTitle text={'Program'} />
        <SectionUnderTitle text={'11/7'} />
        <SectionUnderTitle text={'kl 16:00'} />
        <TextSection text={text} />
    </div>
);

export default Timetable;
