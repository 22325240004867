import React from 'react';
import './SectionTitle.css';

const SectionTitle = ({ text }) => {
    return (
        <div className="section_title-main">
            <div className="section_title-text">{text}</div>
        </div>
    );
};

export default SectionTitle;
