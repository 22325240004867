import React from 'react';
import sodertuna from '../images/sodertunaslott.jpeg';
import SectionTitle from './SectionTitle';
import SectionUnderTitle from './SectionUnderTitle';
import TextSection from './TextSection';
import RoundPicture from './RoundPicture';
import CustomButton from './CustomButton';
import './Place.css';

// const demos = {
//   soundcloud:
//     '<iframe width="100%" height="166" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/379775672&color=%23ff5500&auto_play=true&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"></iframe>',
//
//   plotly:
//     '<iframe src="https://codesandbox.io/embed/q7jmjyplvq?fontsize=14" title="Plotly All Graph Types" allow="geolocation; microphone; camera; midi; vr; accelerometer; gyroscope; payment; ambient-light-sensor; encrypted-media" style="width:100%; height:500px; border:0; border-radius: 4px; overflow:hidden;" sandbox="allow-modals allow-forms allow-popups allow-scripts allow-same-origin"></iframe>'
// };
// <Iframe iframe={demos["soundcloud"]} allow="autoplay" />,
//

// function Iframe(props) {
//   return (
//     <div
//       dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
//     />
//   );
// }
const url =
    'https://www.google.com/maps/place/S%C3%B6dertuna+Castle/@59.0684776,17.3029733,17z/data=!4m18!1m9!3m8!1s0x465f17c3a83894db:0xbebd15129fb8bb40!2sS%C3%B6dertuna+Castle!5m2!4m1!1i2!8m2!3d59.0684776!4d17.305162!3m7!1s0x465f17c3a83894db:0xbebd15129fb8bb40!5m2!4m1!1i2!8m2!3d59.0684776!4d17.305162';
const text =
    'Vi kommer att befinna oss på Södertuna slott, beläget strax utanför Gnesta med utsikt över Frösjön. I anslutning till slottet finns promenadslingor, tennisbana och plats för andra utomhusaktiviteter.';
const text2 =
    'Slottet har funnits sedan 1300-talet och har under åren gått från att vara en sätesgård till det som idag är Södertuna Slott, en förvandling som började under 1700-talet. Under helgen kommer slottet att stå öppet för enbart oss. Med andra ord, ett ypperligt tillfälle för er att utforska slottets alla skrymslen och vrår.';
// https://www.sodertuna.se/om-sodertuna-slott/sodertuna-slotts-historia/
const url2 =
    'https://www.sodertuna.se/om-sodertuna-slott/sodertuna-slotts-historia/';

const Place = ({ placeRef }) => {
    // console.log('Place place ref', placeRef);
    return (
        <div ref={placeRef} className="place">
            <RoundPicture image={sodertuna} />
            <SectionTitle text={'Platsen'} />
            <SectionUnderTitle text={'Södertuna slott'} />
            <SectionUnderTitle text={'646 91 Gnesta'} />
            <div className="place_button_margin">
                <CustomButton
                    text={'Karta'}
                    onClick={() => {
                        window.open(url, '_blank');
                    }}
                />
            </div>
            <TextSection text={text} />
            <TextSection text={text2} />
            <div className="place_button_margin">
                <CustomButton
                    text={'Gå till deras sida'}
                    onClick={() => {
                        window.open(url2, '_blank');
                    }}
                />
            </div>
        </div>
    );
};

export default Place;
