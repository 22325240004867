import React from 'react';
import './Contact.css';

const text1 =
    'Vill du hålla tal eller har du frågor kring schemat för dagen? Någon i följet kommer då att återkomma till dig. ';
const text2 = 'Har du frågor kring, eller önskar du ändra din bokning? ';

const Contact = ({ contactRef }) => (
    <div ref={contactRef} className="contact">
        <div className="contact_title-main">
            <div className="contact_title-text">Kontakt</div>
        </div>
        <div className="contact_box">
            <div className="contact_text-main">
                <div className="contact_text-text">{text1}</div>
            </div>
            <div className="contact_undertitle-main">
                <div className="contact_undertitle-text">
                    dahliadennis2020@gmail.com
                </div>
            </div>
        </div>
        <div className="contact_box">
            <div className="contact_text-main">
                <div className="contact_text-text">{text2}</div>
            </div>
            <div className="contact_undertitle-main">
                <div className="contact_undertitle-text">
                    info.dahliadennis2020@gmail.com
                </div>
            </div>
        </div>
    </div>
);

export default Contact;
