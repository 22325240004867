import React from 'react';
import RsvpForm from './RsvpForm';
import './Rsvp.css';

const Rsvp = ({ rsvpRef }) => (
    <div ref={rsvpRef} className="rsvp">
        <div className="osa-main">
            <div className="osa-text">OSA</div>
        </div>
        <RsvpForm />
    </div>
);

export default Rsvp;
