import React, { Component, useRef, useEffect } from 'react';
import logo from '../logo.svg';
import Header from './Header';
import Hero from './Hero';
import Place from './Place';
import Timetable from './Timetable';
import Logi from './Logi';
import Rsvp from './Rsvp';
import Contact from './Contact';
import Presents from './Presents';
import MoreInfo from './MoreInfo';
import './Site.css';

const Site = () => {
    const placeRef = useRef(null);
    const timetableRef = useRef(null);
    const heroRef = useRef(null);
    const ceremoniRef = useRef(null);
    const rsvpRef = useRef(null);
    const presentsRef = useRef(null);
    const contactRef = useRef(null);
    const moreInfoRef = useRef(null);
    return (
        <div className="site">
            <Header
                placeRef={placeRef}
                timetableRef={timetableRef}
                heroRef={heroRef}
                ceremoniRef={ceremoniRef}
                rsvpRef={rsvpRef}
                presentsRef={presentsRef}
                contactRef={contactRef}
            />
            <Hero heroRef={heroRef} moreInfoRef={moreInfoRef} />
            <MoreInfo moreInfoRef={moreInfoRef} />
            <Rsvp rsvpRef={rsvpRef} />
            <Place placeRef={placeRef} />
            <Timetable timetableRef={timetableRef} />
            <Logi ceremoniRef={ceremoniRef} />
            <Presents presentsRef={presentsRef} />
            <Contact contactRef={contactRef} />
        </div>
    );
};

export default Site;
