import React from 'react';
import './SectionUnderTitle.css';

const SectionUnderTitle = ({ text }) => {
    return (
        <div className="section_undertitle-main">
            <div className="section_undertitle-text">{text}</div>
        </div>
    );
};

export default SectionUnderTitle;
