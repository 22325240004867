import React from 'react';
import './RoundPicture.css';

const RoundPicture = ({ image }) => {
    return (
        <div className="round-picture_main">
            <img className="round-picture" src={image} />
            <div className="round-picture_overlay" />
        </div>
    );
};

export default RoundPicture;
