import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import CustomButton from './CustomButton';
import SectionTitle from './SectionTitle';
import TextSection from './TextSection';
import * as https from 'https';
import * as url from 'url';
import * as emailjs from 'emailjs-com';

import './RsvpForm.css';
const request = require('request');

const CircleLoader = ({ success }) => {
    return (
        <div className="circle-loader_root">
            {success ? <h3>Klart!</h3> : <h3>Laddar...</h3>}
            <div className={`circle-loader ${success ? 'load-complete' : ''}`}>
                <div className={`${success ? 'draw' : 'checkmark'}`}></div>
            </div>
        </div>
    );
};
const Radio = ({ input, children }) =>
    // input should contain checked value to indicate
    // if the input is checked
    console.log(input) || (
        <label>
            <input type="radio" {...input} />
            {children}
        </label>
    );

const Error = ({ name }) => (
    <Field
        name={name}
        subscription={{ touched: true, error: true }}
        render={({ meta: { touched, error } }) =>
            touched && error ? (
                <span className="rsvp_error">{error}</span>
            ) : null
        }
    />
);

// const postToSlack = (attachments, hook_url) => {
//     const slackUrl =
//     url.parse(hook_url);
//
//   const options = {
//     hostname: slackUrl.host,
//     port: 443,
//     path: slackUrl.pathname,
//     method: 'POST',
//     headers: {
//         'Content-Type': 'application/x-www-form-urlencoded'
//     }
//   };
//   console.log(JSON.stringify(options));
//
//   return new Promise((resolve, reject) => {
//     const req = https.request(options, (res) => {
//       res.setEncoding('utf8');
//       let responseBody = '';
//       res.on('data', (chunk) => {
//         responseBody += chunk;
//       });
//       res.on('end', () => {
//           console.log('end');
//           console.log('responseBody', responseBody);
//           resolve('DONE');
//       });
//     });
//
//     req.on('error', function(err) {
//       reject(err);
//     });
//
//     // write data to request body
//     req.write(JSON.stringify(attachments));
//     req.end();
//   });
// };

// const urlWebHook = "https://hooks.slack.com/services/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"; //the URL you get on your "incoming web hooks" page.
//
// function sendToSlack (s, theUsername, theIconUrl, theIconEmoji, theChannel) {
// 	var payload = {
// 		text: s
// 		};
// 	if (theUsername !== undefined) {
// 		payload.username = theUsername;
// 		}
// 	if (theIconUrl !== undefined) {
// 		payload.icon_url = theIconUrl;
// 		}
// 	if (theIconEmoji !== undefined) {
// 		payload.icon_emoji = theIconEmoji;
// 		}
// 	if (theChannel !== undefined) {
// 		payload.channel = theChannel;
// 		}
// 	var theRequest = {
// 		url: urlWebHook,
// 		method: "POST",
// 		json: payload
// 		};
// 	request (theRequest, function (error, response, body) {
// 		if (!error && (response.statusCode == 200)) {
// 			console.log ("sendToSlack: " + s);
// 			}
// 		else {
// 			console.log ("sendToSlack: error, code == " + response.statusCode + ", " + response.body + ".\n");
// 			}
// 		});
// 	}

const RsvpForm = () => {
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [errorState, setErrorState] = useState(false);
    const onSubmit = async (values) => {
        setLoading(true);
        let message = '';
        message = message.concat(
            `Antal Personer: ${values.rsvpform.length}     \n`
        );
        values.rsvpform.forEach((person) => {
            message = message.concat(
                `Namn: ${person.firstName} \n Allergi: ${person.allergi}       \n `
            );
        });
        message = message.concat(`Email: ${values.contactEmail}     \n`);
        message = message.concat(
            `Vi vill stanna på hotelet?: ${values.radio}      \n`
        );
        message = message.concat(`Övrigt: ${values.otherInfo}`);
        try {
            let templateParams = {
                message_html: message,
            };
            await emailjs.send(
                'gmail',
                'template_ERdjCLB7',
                templateParams,
                'user_MeQVLaVLX2FLNcIJrsDXu'
            );
            setSuccess(true);
        } catch (e) {
            setErrorState(true);
        }
        // await sendToSlack ("Hello World");
        return;
    };
    return (
        <>
            {errorState ? (
                <div>
                    <SectionTitle text={'Något gick fel!'} />
                    <TextSection text={'Mail:'} />
                    <div>info.dahliadennis2020@gmail.com</div>
                </div>
            ) : loading ? (
                <CircleLoader success={success} />
            ) : (
                <Form
                    onSubmit={onSubmit}
                    mutators={{
                        // potentially other mutators could be merged here
                        ...arrayMutators,
                    }}
                    initialValues={{
                        rsvpform: [{ firstName: '', lastName: '' }],
                    }}
                    validate={(values) => {
                        const errors = {};
                        if (!values.radio) {
                            errors.radio = 'Du måste välja ett alternativ';
                        }
                        return errors;
                    }}
                    render={({ handleSubmit, pristine, invalid }) => (
                        <form className="rsvp_form" onSubmit={handleSubmit}>
                            <FieldArray name="rsvpform">
                                {({ fields }) => (
                                    <div>
                                        {fields.map((name, index) => (
                                            <div className="person" key={name}>
                                                <div className="person_header">
                                                    <div className="person_header_text">
                                                        Person {index + 1}
                                                    </div>
                                                    {index !== 0 && (
                                                        <button
                                                            className="rsvp_button"
                                                            type="button"
                                                            onClick={() =>
                                                                fields.remove(
                                                                    index
                                                                )
                                                            }
                                                        >
                                                            Ta bort
                                                        </button>
                                                    )}
                                                </div>
                                                <div className="input_box_wrapper">
                                                    <label className="label_osa">
                                                        Namn
                                                    </label>
                                                    <Field
                                                        name={`${name}.firstName`}
                                                        component="input"
                                                        className="input_box_field"
                                                    />
                                                </div>
                                                <div className="input_box_wrapper">
                                                    <label className="label_osa">
                                                        Allergi/Matpreferens
                                                    </label>
                                                    <Field
                                                        name={`${name}.allergi`}
                                                        component="input"
                                                        className="input_box_field"
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                        <div className="rsvp_button_add_person">
                                            <button
                                                type="button"
                                                className="rsvp_button"
                                                onClick={() =>
                                                    fields.push({
                                                        firstName: '',
                                                        lastName: '',
                                                    })
                                                }
                                            >
                                                Lägg till person
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </FieldArray>
                            <div className="input_box">
                                <label className="label_osa">Email</label>
                                <div className="email_text_header">
                                    Vi behöver din email om vi behöver kontakta
                                    dig.
                                </div>
                                <Field
                                    name={`contactEmail`}
                                    component="input"
                                    className="input_box_field"
                                />
                            </div>
                            <div className="input_box">
                                <div className="rsvp_radio_header">
                                    Vill ni sova över på slottet? (1000kr/pers)
                                </div>
                                <div className="rsvp_radio">
                                    <div className="rsvp_radio_button">
                                        <Field
                                            name="radio"
                                            type="radio"
                                            value="yes"
                                            component={Radio}
                                        >
                                            Ja
                                        </Field>
                                    </div>
                                    <div className="rsvp_radio_button">
                                        <label>
                                            <Field
                                                name="radio"
                                                type="radio"
                                                value="no"
                                                component="input"
                                                component={Radio}
                                            >
                                                Nej
                                            </Field>
                                        </label>
                                    </div>
                                </div>
                                <Error name="radio" />
                            </div>
                            <div className="input_box">
                                <label className="label_osa">Övrigt</label>
                                <Field
                                    name={`otherInfo`}
                                    component="textarea"
                                    className="input_box_field"
                                />
                            </div>
                            <div className="rsvp_button_margin">
                                <button
                                    className="rsvp_button_big"
                                    type="submit"
                                >
                                    Skicka
                                </button>
                            </div>
                        </form>
                    )}
                />
            )}
        </>
    );
};

export default RsvpForm;
