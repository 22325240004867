import React from 'react';
import './TextSection.css';

const TextSection = ({ text }) => {
    return (
        <div className="section_text-main">
            <div className="section_text-text">{text}</div>
        </div>
    );
};

export default TextSection;
