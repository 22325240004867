import React, { Component, useRef, useEffect, useState } from 'react';
import useDocumentScrollThrottled from '../hooks/useDocumentScrollThrottled';
import monogram from '../images/Monogram.png';
import menu from '../images/menu.png';
import './Header.css';

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
const scrollToTop = (ref) => window.scrollTo(0, 0);
const MenuButton = ({ mobileOpen }) => {
    // const [open, setOpen] = useState(false);

    // const handleClick = () => {
    //   setOpen(!open);
    // };
    const styles = {
        container: {
            height: '32px',
            width: '32px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '4px',
        },
        line: {
            height: '2px',
            width: '20px',
            background: '#c7b299',
            transition: 'all 0.2s ease',
        },
        lineTop: {
            transform: mobileOpen ? 'rotate(45deg)' : 'none',
            transformOrigin: 'top left',
            marginBottom: '5px',
        },
        lineMiddle: {
            opacity: mobileOpen ? 0 : 1,
            transform: mobileOpen ? 'translateX(0)' : 'none',
        },
        lineBottom: {
            transform: mobileOpen ? 'translateX(-1px) rotate(-45deg)' : 'none',
            transformOrigin: 'top left',
            marginTop: '5px',
        },
    };
    return (
        <div style={styles.container}>
            <div style={{ ...styles.line, ...styles.lineTop }} />
            <div style={{ ...styles.line, ...styles.lineMiddle }} />
            <div style={{ ...styles.line, ...styles.lineBottom }} />
        </div>
    );
};

const Header = ({
    placeRef,
    timetableRef,
    heroRef,
    ceremoniRef,
    rsvpRef,
    presentsRef,
    contactRef,
}) => {
    const [shouldHideHeader, setShouldHideHeader] = useState(false);
    const [shouldShowShadow, setShouldShowShadow] = useState(false);
    const [mobileOpen, setMobileOpen] = useState(false);
    // const scrollToRef = (ref) => {
    //   setMobileOpen(!mobileOpen);
    //   window.scrollTo(0, ref.current.offsetTop);
    // };

    const openMenu = () => setMobileOpen(!mobileOpen);

    const MINIMUM_SCROLL = 80;
    const TIMEOUT_DELAY = 20;

    useDocumentScrollThrottled((callbackData) => {
        const { previousScrollTop, currentScrollTop } = callbackData;
        const isScrolledDown = previousScrollTop < currentScrollTop;
        const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

        setShouldShowShadow(currentScrollTop > 2);

        setTimeout(() => {
            setShouldHideHeader(isScrolledDown && isMinimumScrolled);
        }, TIMEOUT_DELAY);
    });

    const shadowStyle = shouldShowShadow ? 'shadow' : '';
    const hiddenStyle = shouldHideHeader ? 'hidden' : '';
    return (
        <div className={`header ${shadowStyle} ${hiddenStyle}`}>
            <div className="menu_item" onClick={() => scrollToRef(rsvpRef)}>
                OSA
            </div>
            <div className="menu_item" onClick={() => scrollToRef(placeRef)}>
                PLATS
            </div>
            <div
                className="menu_item"
                onClick={() => scrollToRef(timetableRef)}
            >
                10/7
            </div>
            <div className="logo_name" onClick={() => scrollToTop()}>
                <img src={monogram} className="logo_dd" />
            </div>
            <div className="menu_list_button" onClick={openMenu}>
                <MenuButton mobileOpen={mobileOpen} />
            </div>
            <div className="menu_item" onClick={() => scrollToRef(ceremoniRef)}>
                LOGI
            </div>
            <div className="menu_item" onClick={() => scrollToRef(presentsRef)}>
                PRESENTER
            </div>
            <div className="menu_item" onClick={() => scrollToRef(contactRef)}>
                KONTAKT
            </div>
            {mobileOpen && (
                <div className="menu_open">
                    <div
                        className="menu_item_mobile"
                        onClick={() => {
                            openMenu();
                            scrollToRef(rsvpRef);
                        }}
                    >
                        OSA
                    </div>
                    <div
                        className="menu_item_mobile"
                        onClick={() => {
                            openMenu();
                            scrollToRef(placeRef);
                        }}
                    >
                        PLATS
                    </div>
                    <div
                        className="menu_item_mobile"
                        onClick={() => {
                            openMenu();
                            scrollToRef(timetableRef);
                        }}
                    >
                        10/7
                    </div>
                    <div
                        className="menu_item_mobile"
                        onClick={() => {
                            openMenu();
                            scrollToRef(ceremoniRef);
                        }}
                    >
                        LOGI
                    </div>
                    <div
                        className="menu_item_mobile"
                        onClick={() => {
                            openMenu();
                            scrollToRef(presentsRef);
                        }}
                    >
                        PRESENTER
                    </div>
                    <div
                        className="menu_item_mobile"
                        onClick={() => {
                            openMenu();
                            scrollToRef(contactRef);
                        }}
                    >
                        KONTAKT
                    </div>
                </div>
            )}
        </div>
    );
};
// <img src={menu} className="menu_list_button_image"/>

export default Header;
