import React from 'react';
import MoreInfoImage from '../images/corona.png';
import SectionTitle from './SectionTitle';
import RoundPicture from './RoundPicture';
import TextSection from './TextSection';
import SectionUnderTitle from './SectionUnderTitle';
import CustomButton from './CustomButton';
// import Icon from '../images/info-icon.svg';
import { ReactComponent as Icon } from '../images/info-icon.svg';

import './MoreInfo.css';

// const text = '';
// const text2 =
//     'Vad kul att ni hittat hit igen! På grund av rådande omständigheter så har status för vårt firande varit väldigt ovisst. I hopp om att situationen ska förändras så har även inbjudningskorten försenats. ';
// const text3 =
//     'Som ni förstår så är läget fortsatt oklart men vi hoppas ännu kunna hålla vårt bröllop, ungefär som planerat i sommar. Vår ambition är självklart att restriktioner samt rekommendationer ska kunna följas väl på plats den 10e juli, vilket kan komma att påverka både dagens program samt gästantalet. Vi hoppas att ni har överseende med detta!';
// const text4 =
//     'Under tiden får ni gärna OSA på nytt igen om ni vill och kan komma, även denna gång här på hemsidan. ';
// const text5 =
//     'Vi har full förståelse för att man önskar avböja inbjudan på grund av rådande omständigheter, detta är ingenting vi tar personligt och respekterar till fullo!';

// const MoreInfo = ({ moreInfoRef }) => (
//     <div ref={moreInfoRef} className="more_info">
//         <RoundPicture image={MoreInfoImage} />
//         <SectionTitle text={'Datum flyttat'} />
//         <SectionUnderTitle text={'Kära gäster'} />
//         <TextSection text={text} />
//         <TextSection text={text2} />
//         <TextSection text={text3} />
//         <TextSection text={text4} />
//         <TextSection text={text5} />
//     </div>
// );

const text2 =
    'Vad kul att ni hittat hit igen! På grund av rådande omständigheter så har status för vårt firande varit väldigt ovisst. I hopp om att situationen ska förändras så har även inbjudningskorten försenats. ';
const text3 =
    'Vad kul att ni hittat hit igen! På grund av rådande omständigheter så har status för vårt firande varit väldigt ovisst. I hopp om att situationen ska förändras så har även inbjudningskorten försenats. ';
const MoreInfo = ({ moreInfoRef }) => (
    <div ref={moreInfoRef} className="more_info">
        <div className="more_info_round-picture_main">
            <Icon className="more_info_hero_information_icon" />
        </div>
        <SectionTitle text={'Ny information'} />
        <SectionUnderTitle text={'Kära gäster'} />
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Nu är det äntligen snart dags att fira! Dessvärre kommer den
                efterlängtade 10e juli inte att ägnas på Södertuna Slott längre.
                På grund av diverse svårigheter i planeringen av en trygg dag
                tillsammans så har vi valt att istället samlas ute i Gudö
                utanför Stockholm för en mottagning och sommarfest, allt
                utomhus. Detta innebär alltså att vi inte kommer att kunna
                erbjuda sovplatser i samma utsträckning, och i viss mån så
                lämnar vi nu över denna lördags öde i vädergudarnas händer.
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Allt hänger förstås på att de kommande restriktionerna från
                Folkhälsomyndigheten och Regeringen gäller enligt den
                presenterade tidsplanen. Skulle något komma att förändras
                gällande t.ex. smittspridning för Sverige så kommer vi
                självfallet att meddela er så snart vi kan.
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Vi hoppas innerligt att dessa ändrade planer inte ställer till
                det allt för mycket för er, och att vi (trots avsaknaden av
                slott) kan få träffas och fira tillsammans i den mån möjligt den
                10 Juli.
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Vi har full förståelse för att man önskar avböja inbjudan på
                grund av rådande omständigheter, detta är ingenting vi tar
                personligt och något vi respekterar till fullo! Vi ber er dock
                att meddela oss så snart ni har möjlighet, så att vi har
                möjlighet att planera. info.dahliadennis2020@gmail.com
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Mer information inom kort!
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Varma hälsningar,<br></br> Dahlia & Dennis
            </p>
        </div>
        {/* <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Vad kul att ni hittat hit igen! På grund av rådande
                omständigheter så har status för vårt firande varit väldigt
                ovisst. I hopp om att situationen ska förändras så har även
                inbjudningskorten försenats.
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Som ni förstår så är läget fortsatt oklart men vi hoppas ännu
                kunna hålla vårt bröllop, ungefär som planerat i sommar.
                <strong>
                    {' '}
                    Vår ambition är självklart att restriktioner samt
                    rekommendationer ska kunna följas till fullo väl på plats
                    den 10e juli
                </strong>
                , vilket kan komma att påverka både dagens program samt
                gästantalet. Vi hoppas att ni har överseende med detta!
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>
                    Under tiden får ni gärna OSA på nytt om ni vill och kan
                    komma, även denna gång här på hemsidan.
                </strong>
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Vi har full förståelse för att man önskar avböja inbjudan på
                grund av rådande omständigheter, detta är ingenting vi tar
                personligt och något vi respekterar till fullo!
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <strong>
                    Om vi skulle inse att dagen behöver förändras/begränsas på
                    något sätt så lovar vi att informera Er så snart vi kan
                </strong>
                . Vi kommer att publicera den typen av nyheter här på hemsidan,
                men även i ett mejl till de mejladresser som man har angivit när
                man OSA:t.
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                <i>
                    Vi har beslutat oss för att begränsa gästlistan till de som
                    OSA:de förra året. Detta är såklart väldigt väldigt tråkigt
                    men något vi tror är nödvändigt på grund av den rådande
                    pandemin. Drömscenariot hade förstås varit att få fira denna
                    dagen med så många av våra nära & kära som möjligt.
                </i>
            </p>
        </div>
        <div className="more_info_section_text-main">
            <p className="more_info_section_text-text">
                Varma hälsningar,<br></br> Dahlia & Dennis
            </p>
        </div> */}
    </div>
);

export default MoreInfo;
