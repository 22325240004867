import React from 'react';
import './CustomButton.css';

const CustomButton = ({ text, onClick }) => (
    <button className="custom_button" onClick={onClick}>
        {text}
    </button>
);

export default CustomButton;

// import React from 'react';
// import './CustomButton.css';
//
// const CustomButton = ({ text, onClick }) => (
//     <div class="rsvp_button rsvp_button-three">
//       <span>text</span>
//     </div>
// );
//
// export default CustomButton;
