import React from 'react';
import logi from '../images/logi.jpg';
import SectionTitle from './SectionTitle';
import RoundPicture from './RoundPicture';
import TextSection from './TextSection';
import SectionUnderTitle from './SectionUnderTitle';
import CustomButton from './CustomButton';

import './Logi.css';

const text =
    'Du som inte vill behöva tänka på refrängen, eller för den delen inte vill behöva röra dig  hemåt under kvällen har möjlighet att sova kvar i en av slottets flyglar. Priset är då 1000 kr per person. Dagen efter ingår frukostbuffé.';

const Logi = ({ ceremoniRef }) => (
    <div ref={ceremoniRef} className="logi">
        <RoundPicture image={logi} />
        <SectionTitle text={'Logi'} />
        <SectionUnderTitle text={'1000 kr/person'} />
        <TextSection text={text} />
    </div>
);

export default Logi;
