import React from 'react';
import logo from './logo.svg';
import Site from './components/Site';
import './App.css';

function App() {
    return (
        <div className="App">
            <Site />
        </div>
    );
}

export default App;
